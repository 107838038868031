<template>
    <div>
        <b-row>
            <b-col cols="12" class="mb-2">
                <div class="bg-white
                text-center
                d-flex
                justify-content-center
                align-items-center
                p-2
                box-shadow-1
                ">
                    <b-link
                        v-if="companyData"
                        :to="companyData.PublicUrl"
                        class="comp-logo-link d-flex align-items-center"
                        target="_blank"
                    >
                        <b-img
                            v-if="companyData.LogoUrl"
                            :src="companyData.LogoUrl"
                            class="comp-logo pl-1"
                        />
                        <h2 class="pt-1">{{ companyData.Label }}</h2>
                    </b-link>

                    <div class="sk-invoice-controls mx-2">
                        <b-button
                            v-if="false"
                            variant="outline-primary"
                            class="ml-2"
                            @click="printInvoice"
                        >
                            <font-awesome-icon
                                icon="print"
                                class="mr-1"
                            />
                            {{i18nT(`Print`)}}
                        </b-button>
                        <b-button
                            variant="outline-primary"
                            @click="downloadInvoice"
                            class="ml-2"
                            :disabled="!invoiceData.QuoteDownloadUrl"
                        >
                            <font-awesome-icon
                                icon="download"
                                class="mr-1"
                            />
                            {{i18nT(`Download`)}}
                        </b-button>

                        <b-button
                            v-if="invoiceData && invoiceData.Status === 'pending'"
                            @click="changeStatus('accepted')"
                            variant="primary"
                            class="ml-2">
                            {{i18nT(`Accept offer`)}}
                        </b-button>

                        <b-button
                            v-if="invoiceData && invoiceData.Status === 'pending'"
                            @click="changeStatus('rejected')"
                            variant="danger"
                            class="ml-2">
                            {{i18nT(`Reject offer`)}}
                        </b-button>

                        <b-button
                            v-if="invoiceData && invoiceData.Status === 'accepted'"
                            variant="outline-primary"
                            :disabled="true"
                            class="ml-2">
                            {{i18nT(`Already accepted`)}}
                        </b-button>

                        <b-button
                            v-if="invoiceData && invoiceData.Status === 'rejected'"
                            variant="outline-danger"
                            :disabled="true"
                            class="ml-2">
                            {{i18nT(`Quote rejected`)}}
                        </b-button>
                    </div>

                    <div style="width: 120px">
                        <b-form-select
                            v-model="locale"
                            :options="cultures"
                            label="text"
                        />
                    </div>

                </div>
            </b-col>

            <b-col cols="12" md="8" offset-md="2" v-if="invoiceData && invoiceData.IframePublicUrl">
                <iframe id="print-frame" :src="invoiceData.IframePublicUrl+`&lang=`+locale" width="100%" height="1000px" frameborder="0"></iframe>
            </b-col>
        </b-row>


    </div>
</template>

<script>
import {
    BButton,
    BRow,
    BCol,
    // BInputGroup,
    // BInputGroupText,
    //BFormGroup,
    // BFormRadio,
    // BFormInput,
    BFormSelect,
    // BCard,
    // BCardHeader,
    // BCardBody,
    // BCardText,
    // BModal,
    // BListGroup,
    // BListGroupItem,
    //BFormSelectOption,
    // BForm,
    // BFormFile,
    // BFormTextarea,
    BImg,
    // BTabs,
    // BTab,
    BLink,
    // BInputGroupAppend,
    // BFormCheckbox
    //BCarousel,
    //BCarouselSlide,
    // BSpinner
} from 'bootstrap-vue'

// import {dictToSelectArray} from '@core/utils/utils'
import store from "@/store";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {dictToSelectArray} from "@core/utils/utils";
import { required, email} from '@validations'

export default {
    components: {
        BButton,
        BRow,
        BCol,
        // BModal,
        //BFormGroup,
        BFormSelect,
        // BFormRadio,
        // BFormInput,
        // BListGroup,
        // BListGroupItem,
        //BFormSelectOption,
        BLink,
        BImg,
        // BSpinner,
        // ValidationProvider,
        // ValidationObserver,
        //BCarousel,
        //BCarouselSlide,
        //StripeElementCard
    },
    data() {
        return {
            //Validations
            required,
            email,

            locale: 'en',
            cultures: [],
            selectedPaymentMethod: null,
            methodDetails: {
                Amount: 0,
            },
            loading: false,
            amountMode: 'full',
            paymentType: 'card',

            paymentStep: 1,
            payment_id: null,

            //STRIPE DATA
            stripe_pk: null,
            stripe_customer_id: null,
            stripe_account_id: null,

            paymentIntent: null,

            //STRIPE ELEMENTS
            cardElement: null,
            bankElement: null,
        }
    },
    computed: {
        curInvoiceId(){
            return this.$route.params.id;
        },
        invoiceData(){
            return store.getters['invoices/getQuoteData'];
        },
        paymentProviders(){
          return store.getters['invoices/getPaymentProviders'];
        },
        paymentMethods(){
            if(this.paymentProviders){
                return this.paymentProviders.map((item) => {

                    return {
                        value: item.Id,
                        text: item.Label
                    }
                })
            } else {
                return [];
            }
        },
        companyData(){
            if(this.invoiceData){
                return    this.invoiceData.WorkspaceObj;
            } else {
                return {};
            }
        },
    },
    created() {
        let params = {
            id: this.$route.params.id,
            hash: this.$route.params.hash
        }

        store.dispatch('invoices/fetchPublicQuoteDetails', params);
        if (this.$route.query.lang) {
            this.locale = this.$route.query.lang
        }

        this.$http.get(`system/cultures`).then(({data}) => {
            this.cultures = dictToSelectArray(data.data)
        })

    },
    methods: {

        changeStatus(status){
            let formData = new FormData();
                formData.append('Status', status);
                formData.append('quote_id', this.curInvoiceId);
                formData.append('hash', this.$route.params.hash);



            this.$http.post(`quotes/publicQuoteStatus`, formData).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.i18nT(`Success`),
                        icon: 'CheckIcon',
                        variant: 'success',
                        message: status == 'accepted' ? this.i18nT(`Quote accepted successfully`) : this.i18nT(`Quote rejected successfully`)
                    },

                });
                let params = {
                    id: this.$route.params.id,
                    hash: this.$route.params.hash
                }
                store.dispatch('invoices/fetchPublicQuoteDetails', params);

            })
        },

        showError(message){
            if(!message) { message = this.i18nT(`An error has occurred. Please try again later.`) }
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    message: message
                },
            });
            this.loading = false;
        },


        printInvoice(){
            let iframe = document.getElementById('print-frame');
            iframe.contentWindow.print();
        },

        downloadInvoice() {
            window.open(this.invoiceData.QuoteDownloadUrl+'&lang='+this.locale, '_blank');
        },

        selectPaymentMethod(){
            this.$bvModal.show('method-select')
            this.methodDetails.Amount = this.invoiceData.DueAmount;
            //this.$router.push({name: 'payment-methods', params: {id: this.curInvoiceId}});
        },

    },
    watch: {
        locale: function (val) {
            this.changeLang(val)
            history.pushState({}, null, `${this.$route.path}?lang=${val}`)
        },
        paymentProviders(){
            if(this.paymentProviders){
                this.selectedPaymentMethod = this.paymentProviders[0].Id;
            }
        },
        curInvoiceId (val, oldVal) {
            if(val && val !== oldVal)
                store.dispatch('invoices/fetchPublicInvoiceDetails', val);
        }

    }
}
</script>

<style lang="scss" scoped>

.sk-spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}


div#stripe-card-row {
    padding-left: 1rem;
    padding-right: 1rem;
}


#stripe-card-row > div {
    width: 100%;
}

#stripe-element-form {
    width: 100%;
    height: 100%;
}

.custom-radio .custom-control-input {
    margin-top: 20px;
}

.sk-step-mingheight {
    min-height: 220px;
}

.sk-provider-icon {
    color: var(--primary);
    margin-bottom: -11px;
    margin-left: 4px;
}

.sk-provider-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sk-one-provider {
    width: 110px;
    height: 120px;
    border-radius: 5px;
}

.sk-selected {
    box-shadow-color: var(--primary);
}

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.card .card-header {
    justify-content: left;
    border-bottom: 5px solid var(--primary);
    margin-bottom: 10px;
}

.card .card-header h4 {
    margin-bottom: 0 !important;
}

.comp-logo {
    max-height: 60px;
    margin-right: 20px;
}

.comp-links {
    margin-left: auto;
}

.comp-links svg {
    margin-left: 10px;
}
</style>
